import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";

export default class extends Controller {
  connect() {
    var calendarEl = document.getElementById("calendar");
    var calendar = new Calendar(calendarEl, {
      plugins: [
        timeGridPlugin,
        dayGridPlugin,
        listPlugin,
        bootstrapPlugin,
        interactionPlugin,
      ],
      initialView: $(window).width() < 765 ? "timeGridFourDay" : "timeGridWeek",
      // aspectRatio: $(window).width() < 765 ? 0.8 : 1.35,
      views: {
        timeGridFourDay: {
          type: "timeGrid",
          duration: { days: 3 },
        },
      },
      headerToolbar: {
        start: "prev,next today",
        center: "title",
        end: "dayGridMonth,timeGridWeek,listWeek",
      },
      buttonText: {
        today: "oggi",
        month: "mese",
        week: "settimana",
        day: "giorno",
        list: "lista",
      },
      height: "auto",
      themeSystem: "bootstrap",
      events: "/events.json",
      timeZone: "local",
      locale: "it",
      allDaySlot: true,
      nowIndicator: true,
      editable: false,
      scrollTime: "08:00",
      firstDay: 1,
      eventOverlap: true,
      businessHours: {
        daysOfWeek: [1, 2, 3, 4, 5, 6],
        startTime: "08:00",
        endTime: "20:00",
      },
      slotMinTime: "08:00:00",
      slotMaxTime: "20:00:00",
      expandRows: true,
      dateClick: function (info) {
        var hours = info.date.getUTCHours();
        var extraHours = true;
        if (extraHours || (hours >= 6 && hours < 20)) {
          if (info.date < new Date()) {
            // console.log("no");
          } else {
            window.location = "/events/new?start_time=" + info.dateStr;
          }
        }
      },
      eventDidMount: function (info) {
        // info.el.getElementsByClassName("fc-event-title")[0].innerHTML =
        //   "<b>" +
        //   info.event.title +
        //   "</b>" +
        //   '<br><span style="font-size: 10px">' +
        //   info.event.extendedProps.description +
        //   "</span>";
        // console.log(info.el);
        var element = info.el.getElementsByClassName("fc-event-title")[0];
        element.innerHTML =
          "<b>" +
          element.innerHTML +
          "</b>" +
          '<span style="font-size: 10px">' +
          info.event.extendedProps.description +
          "</span>";
      },
      eventResize: function (info) {
        eventUpdate(info);
      },
      eventDrop: function (info) {
        eventUpdate(info);
      },
    });
    calendar.render();
  }
}

function eventUpdate(info) {
  $.ajax({
    url: "/events/" + info.event.id,
    data: {
      event: {
        start_time: info.event.start.toISOString(),
        end_time: info.event.end.toISOString(),
      },
    },
    type: "PATCH",
    dataType: "json",
    success: function (json) {
      // alert('Added Successfully');
    },
    error: function (json) {
      alert("Errore: controllare gli orari");
      info.revert();
    },
  });
}
