document.addEventListener("turbolinks:before-cache", function () {
  $(
    ".search-select, .search-select-brand, .search-select-model, .search-select-state"
  ).select2("destroy");
  try {
    $(".datepicker").flatpickr().destroy();
  } catch (error) {}
  try {
    $(".datetimepicker").flatpickr().destroy();
  } catch (error) {}
});

document.addEventListener("turbolinks:load", function () {
  $(".search-select").select2();

  flatpickr(".datepicker", {
    altInput: true,
    altFormat: "d/m/Y",
    dateFormat: "Y-m-d",
    locale: "it",
  });

  flatpickr(".datetimepicker", {
    altInput: true,
    altFormat: "d/m/Y H:i",
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    locale: "it",
    time_24hr: true,
  });

  $(".no-propagation").click(function (e) {
    e.stopPropagation();
  });

  $(".sale-form, .quotation-form").on(
    "cocoon:after-insert",
    function (e, insertedItem, originalEvent) {
      $(".search-select").select2();
      flatpickr(".datepicker", {
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        locale: "it",
      });
      $(insertedItem).on("change keyup select2:selecting", function (emitter) {
        if ($(insertedItem).hasClass("nested-fields")) {
          app.updatePayments(emitter);
        }
        if ($(insertedItem).hasClass("extra-cost-fields")) {
          app.updateExtraCosts(emitter);
        }
      });
    }
  );
  $(".sale-form, .quotation-form").on("cocoon:after-remove", function () {
    app.updatePayments(null);
    app.updateExtraCosts(null);
  });
  $(".sale-form .nested-fields, .quotation-form .nested-fields").on(
    "change keyup select2:selecting",
    function (emitter) {
      app.updatePayments(emitter);
    }
  );
  $(".sale-form .extra-cost-fields, .quotation-form .extra-cost-fields").on(
    "change keyup select2:selecting",
    function (emitter) {
      app.updateExtraCosts(emitter);
    }
  );
});
