// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
global.jQuery = require("jquery");
global.$ = require("jquery");
require("jquery-ui/ui/widgets/sortable");
require("src/sortable");
require("src/cocoon");
global.axios = require("axios");
global.moment = require("moment");
require("custom");
require("chartkick");
require("chart.js");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import "./stylesheets/application.scss";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  window.setTimeout(function () {
    $(".alert-autohide")
      .fadeTo(500, 0)
      .slideUp(500, function () {
        $(this).remove();
      });
  }, 3000);
});
import "controllers";
